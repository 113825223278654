<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div class="misc-wrapper">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="top: 0rem !important;"
      >
        <div>
          <td style="vertical-align: middle;">
            <logo
              style="height: 50px; width: auto; margin-top: 1rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text text-primary"
              style="margin-top: 20px; margin-left: 0.5rem; text-align: center;"
            >
              {{ appName }}
            </h2>
          </td>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <div class="misc-inner p-2 p-sm-3 mt-5">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ $t('Connect to YRU Passport') }}
          </h2>
          <p class="mb-2">
            {{ $t('Confirm and verify your information' ) }}
          </p>
          <b-card
            v-if="yruProfile.id > 0"
            style="border-radius: 15px;"
            no-body
            class="px-1 py-2 mx-1 my-2"
          >
            <b-row>
              <b-col cols="12">
                <b-alert
                  show
                  fade
                  variant="success"
                  class="mx-0 mt-0 mb-75"
                >
                  <div
                    class="alert-body"
                    style="font-size: 18px;"
                  >
                    {{ $t('Are you this user?') }}
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-50">
              <b-col>
                <div class="d-flex justify-content-start">
                  <b-avatar
                    size="60"
                    :src="(yruProfile.avatar !== '')? yruProfile.avatar : ''"
                    :text="avatarText(yruProfile.name)"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  />
                  <div class="d-flex flex-column ml-2 text-left">
                    <b-row>
                      <b-col cols="12">
                        <span
                          class="text-with-dots"
                          style="font-size: 18px;"
                        >
                          {{ yruProfile.name }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="mt-50">
                      <b-col cols="12">
                        <span
                          class="text-with-dots"
                          style="font-size: 16px;"
                        >
                          {{ yruProfile.email }}
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                cols="7"
                class="pr-50"
              >
                <b-button
                  variant="outline-primary"
                  class="mt-2 mx-0 w-100"
                  @click="onLogout"
                >
                  <feather-icon icon="ChevronLeftIcon" />
                  {{ $t('Logout this user') }}
                </b-button>
              </b-col>
              <b-col
                cols="5"
                class="pl-50"
              >
                <b-button
                  variant="primary"
                  class="mt-2 mx-0 w-100"
                  @click="onConfirm"
                >
                  <feather-icon icon="CheckCircleIcon" />
                  {{ $t('Yes, confirm') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-alert
            v-else
            show
            fade
            class="mb-2 mx-1"
            variant="danger"
          >
            <div class="alert-body">
              <span>{{ $t('Error, invalid data') }}</span>
            </div>
          </b-alert>
          <div>
            <b-button
              variant="primary"
              class="mt-0"
              @click="onBack"
            >
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('Back') }}
            </b-button>
          </div>
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
  BOverlay,
  BAlert,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import Logo from '@/@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import {
  Toast,
  Dialog,
} from 'vant'
import {
  onUnmounted,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import storeLineOa from '@/views/line/oa/storeLineOa'
import storeYrupassport from './storeYrupassport'

export default {
  components: {
    BLink,
    BImg,
    BOverlay,
    BAlert,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    Logo,
  },
  data() {
    return {
      showOverlay: false,
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      skin: store.state.appConfig.layout.skin,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    routeParams() {
      return this.$route.params
    },
    lineOaId() {
      const { lineOaId } = store.state.lineLiff.line
      return lineOaId
    },
    yruProfile() {
      return store.state.yruPassport.profile
    },
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'auth-login' })
    },
    onLogout() {
      Dialog.confirm({
        title: this.$i18n.t('Confirmation'),
        message: this.$i18n.t('Do you want to logout YRU Passort?'),
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonText: this.$i18n.t('Yes, logout'),
        confirmButtonColor: 'green',
      })
        .then(() => {
          // on confirm
          this.showOverlay = true
          store.dispatch('yruPassport/logoutUrl', {
            key: this.routeParams.key,
            liffid: this.routeParams.liffid,
            redirectUri: 'yrupassport/auth',
            // redirectUri: 'auth-login',
            mode: 'confirm',
          })
            .then(response => {
              this.showOverlay = false
              if (response.status === true) {
                window.location.href = response.url
              }
            })
            .catch(error => {
              this.showOverlay = false
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                Toast({
                  message: errorMsg,
                  icon: 'warning-o',
                  position: 'middle',
                  duration: 4000,
                })
              } else if (message) {
                Toast({
                  message,
                  icon: 'warning-o',
                  position: 'middle',
                  duration: 4000,
                })
              }
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    onConfirm() {
      Dialog.confirm({
        title: this.$i18n.t('Confirmation'),
        message: this.$i18n.t('Do you want confirm?'),
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonText: this.$i18n.t('Yes, confirm'),
        confirmButtonColor: 'green',
      })
        .then(() => {
          // on confirm
          this.yrupassportLogin()
        })
        .catch(() => {
          // on cancel
        })
    },
    yrupassportLogin() {
      if (store.state.yruPassport.profile !== undefined && store.state.yruPassport.profile !== ''
      && store.state.yruPassport.token !== undefined && store.state.yruPassport.token !== '') {
        this.showOverlay = true
        const params = {
          appCode: ['booking', 'master'],
          token: store.state.yruPassport.token,
          profile: store.state.yruPassport.profile,
        }
        store.dispatch('store-yrupassport-profile/yrupassportLogin', params)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            const { status, code } = response.data

            if (status === 'ok') {
              this.showOverlay = false
              Toast({
                message: this.$i18n.t('Process on successfully'),
                icon: 'success',
                position: 'middle',
                duration: 3000,
              })

              // console.log(response.data)
              const { userData } = response.data
              if (userData !== undefined && userData !== '') {
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)
                // console.log(userData)
                localStorage.setItem(`${$themeConfig.app.commonSession}-user-data`, JSON.stringify(userData))
                this.$ability.update(userData.ability)

                // get line oa if total = 1 to auto select
                store.dispatch('login-line-oa/getListLineOa', {
                  q: '',
                  sortBy: '',
                  page: '',
                  perPage: '',
                  userId: userData.id,
                })
                  .then(lineoa => {
                    const { list, total } = lineoa.data
                    if (total === 1) {
                      const lineOa = JSON.stringify(list[0])
                      store.commit('lineapi/UPDATE_LINE_OA', lineOa)
                      this.$router.push({ name: 'home' })
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: `${this.$i18n.t('Welcome')} ${userData.fullName || userData.username}`,
                              icon: 'CoffeeIcon',
                              variant: 'success',
                              text: `${this.$i18n.t('You have successfully logged in as')} ${userData.role}`,
                            },
                          })
                        })
                    } else {
                      this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: `${this.$i18n.t('Welcome')} ${userData.fullName || userData.username}`,
                              icon: 'CoffeeIcon',
                              variant: 'success',
                              text: `${this.$i18n.t('You have successfully logged in as')} ${userData.role}`,
                            },
                          })
                        })
                    }
                  })
              } else {
                Toast({
                  message: this.$i18n.t('Invalid data'),
                  position: 'middle',
                  duration: 4000,
                })
              }
            } else if (status === 'error') {
              this.showOverlay = false
              if (code === 'invalid_data') {
                Toast({
                  message: this.$i18n.t('Invalid data'),
                  position: 'middle',
                  duration: 4000,
                })
              } else if (code === 'not_connect') {
                Toast({
                  message: this.$i18n.t('Not connected'),
                  position: 'middle',
                  duration: 4000,
                })
                this.$router.push({ name: 'connect', params: { lineOaId: this.line.lineOaId, profile: this.profile } })
              } else if (code === 'process_on_error') {
                Toast({
                  message: this.$i18n.t('Process on error'),
                  position: 'middle',
                  duration: 4000,
                })
              }
            }
          })
          .catch(error => {
            this.showOverlay = false
            this.otpData = ''
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              Toast({
                message: errorMsg,
                icon: 'warning-o',
                position: 'middle',
                duration: 4000,
              })
            } else if (message) {
              Toast({
                message,
                icon: 'warning-o',
                position: 'middle',
                duration: 4000,
              })
            }
          })
      }
    },
  },
  setup() {
    const { appName } = $themeConfig.app

    if (!store.hasModule('store-yrupassport-profile')) store.registerModule('store-yrupassport-profile', storeYrupassport)
    onUnmounted(() => {
      if (store.hasModule('store-yrupassport-profile')) store.unregisterModule('store-yrupassport-profile')
    })
    if (!store.hasModule('login-line-oa')) store.registerModule('login-line-oa', storeLineOa)
    onUnmounted(() => {
      if (store.hasModule('login-line-oa')) store.unregisterModule('login-line-oa')
    })

    return {
      appName,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
